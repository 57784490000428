$('#mobile_menu').click(function () {
    $('.header #menu').stop().slideToggle(500, 'easeInOutExpo');
});

$(document).ready(function () {
    $('.banner').flickity({
        cellAlign: 'left',
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true
    });

    $("#menu a").click(function (event) {
        let headerHeight;
        console.log(this.hash);
        if (this.hash !== "") {
            event.preventDefault();

            const hash = this.hash;
            headerHeight = $(".header").height() + 30;
            if ($("#mobile_menu").is(":visible")) {
                headerHeight = $(".logo").height() + 70;
            }

            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top - headerHeight
                },
                1000,
                "easeOutQuart",
                function () {
                    if ($('#mobile_menu').is(':visible')) {
                        $(".header #menu").slideUp(500, "easeInOutExpo");
                    }
                }
            );
        }
    });
});


